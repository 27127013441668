import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

const Pagination = props => {
  const { meta, callback, scrollRef, scrollTop } = props

  const { currentPage, totalPages, previousPage, nextPage, totalEntries } = meta

  if (parseInt(currentPage, 10) <= 0) return null
  if (parseInt(totalPages, 10) <= 1) return null

  const handleClick = useCallback((page) => {
    callback(page)
    scrollRef?.current?.scrollIntoView()
    if (scrollTop) {
      window.scroll(0, 0)
    }
  }, [meta])

  return (
    <div className="bg-white dark:bg-gray-700 rounded p-2 flex items-center justify-between">
      <div className="flex-1 flex justify-between sm:hidden items-center">
        <a onClick={() => handleClick(previousPage)} className="text-xs relative inline-flex items-center p-2 border border-gray-300 dark:border-gray-800 rounded-md text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer" > Previous </a>
        <div className="text-xs">{totalEntries == '10000' ? 'More than 10,000' : totalEntries} results</div>
        <a onClick={() => handleClick(nextPage)} className="text-xs relative inline-flex items-center p-2 border border-gray-300 dark:border-gray-800 rounded-md text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer" > Next </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <p className="text-sm text-gray-700 dark:text-gray-300">
          <span className="font-medium">{totalEntries == '10000' ? 'More than 10,000' : totalEntries}</span> results
        </p>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <a onClick={() => handleClick(previousPage)} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 dark:border-gray-800 text-sm font-medium text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer">
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            { currentPage > 1 && <>
              <a onClick={() => handleClick(1)} className="border-gray-300 dark:border-gray-800 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer">1</a>
            </> }
            { currentPage > 2 && <>
              <a onClick={() => handleClick(2)} className="border-gray-300 dark:border-gray-800 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer">2</a>
            </> }
            { previousPage > 3 && <span className="border-gray-300 dark:border-gray-800 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-not-allowed">...</span> }
            { previousPage > 2 && <>
              <a onClick={() => handleClick(previousPage)} className="border-gray-300 dark:border-gray-800 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer">{previousPage}</a>
            </> }
            <span className=" border-gray-300 dark:border-gray-800 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 relative inline-flex items-center px-4 py-2 border text-sm font-medium z-10 bg-cccblue bg-opacity-10 border-cccblue cursor-not-allowed" > {currentPage} </span>
            { nextPage && nextPage <= totalPages - 2 && <a onClick={() => handleClick(nextPage)} className="border-gray-300 dark:border-gray-800 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"> {nextPage}  </a> }
            { nextPage && nextPage < totalPages - 2 && <span className="border-gray-300 dark:border-gray-800 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-not-allowed"> ...  </span> }
            { currentPage < totalPages - 1 && <>
              <a onClick={() => handleClick(totalPages - 1)} className='border-gray-300 dark:border-gray-800 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer'>{totalPages - 1}</a>
            </> }
            { currentPage < totalPages && <>
              <a onClick={() => handleClick(totalPages)} className='border-gray-300 dark:border-gray-800 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer'>{totalPages}</a>
            </> }
            <a onClick={() => handleClick(nextPage)}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 dark:border-gray-800 text-sm font-medium text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer" >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  )
}
export default Pagination

Pagination.propTypes = {
  meta: PropTypes.object.isRequired,
  callback: PropTypes.func,
  scrollRef: PropTypes.object,
  scrollTop: PropTypes.func
}
