import React from 'react'
import PropTypes from 'prop-types'
import { motion } from "framer-motion";
import classNames from '@/utils/classNamesLocal'

const variants = {
  initial: {
    scaleY: 0.5,
    opacity: 0,
  },
  animate: {
    scaleY: 1,
    opacity: 1,
    transition: {
      repeat: Infinity,
      repeatType: "mirror",
      duration: 1,
      ease: "circIn",
    },
  },
};


const messages = [
  'You look nice today.',
  'Recombobulating our discombobs',
  'Brb, yeeting',
  'Errors are red, my screen is blue, I think I deleted System32',
  'Cant find it. One sec',
  'Suspenseful music playing...',
  'The most important meal of the day is the one with donuts',
  'Asking Buford',
  'Cat videos. hahah, love \'em.',
  '555',
  '하하하',
  'あはは',
  'According to the laws of gravity, I am attractive',
  'Adding something to my Netflix watchlist',
  'We require additional servers!',
  'Yo dawg, heard you liked open projects',
  'Payload incoming',
  'I am loading the loading screen',
  'The mind is like a parachute, it works best when open',
  'Geared up and ready to go',
  'Requesting additional pylons',
  'Computing with computers',
  'Thermometers are speedometers for atoms',
  'By the power of SCIENCE!',
  'Sloths are the best at starting slow-claps',
  'I always thought you were great',
  'Having one bar of wifi is worse than having no wifi at all',
  'You are pressing the button too hard!',
  'Sometimes I just like to sit with a tea',
  'RIP Servers',
  'Dont forget to like and subscribe',

]

function getMessage() {
  return messages[Math.floor(Math.random() * messages.length)]
}

const styles = {
  full: 'fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 text-gray-300 dark:text-white',
  player: 'h-37px w-37px text-white',
  inline: 'text-gray-800 dark:text-white'
}

// spinner with a color
/*
<svg role="status" class="inline h-8 w-8 animate-spin mr-2 text-gray-200 dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
</svg>
*/

const Loading = ({ full, noMessage, noLoadingMessage, player }) => {
  const showMessage = typeof (noMessage) === 'undefined'
  const showLoadingMessage = typeof (noLoadingMessage) === 'undefined'
  return <div className={classNames(full === true ? styles.full : styles.inline, 'flex flex-col items-center justify-center')}>
    <motion.div
      transition={{
        staggerChildren: 0.25,
      }}
      initial="initial"
      animate="animate"
      className="flex gap-1"
    >
      <motion.div variants={variants} className="h-12 w-2 bg-cccblue" />
      <motion.div variants={variants} className="h-12 w-2 bg-cccpurple" />
      <motion.div variants={variants} className="h-12 w-2 bg-cccorange" />
      <motion.div variants={variants} className="h-12 w-2 bg-cccorange" />
      <motion.div variants={variants} className="h-12 w-2 bg-cccpurple" />
      <motion.div variants={variants} className="h-12 w-2 bg-cccblue" />
    </motion.div>
    { showLoadingMessage && <h2 className="text-center text-xl font-semibold">Loading...</h2> }
    { showMessage && <p className="w-1/3 text-center">{getMessage()}</p> }
  </div>
}

export default Loading

Loading.propTypes = {
  full: PropTypes.bool,
  noMessage: PropTypes.bool,
  player: PropTypes.bool,
  noLoadingMessage: PropTypes.bool
}
